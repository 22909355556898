<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack" class="cur-a"
            >人才库</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">备忘</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                class="bgc-bv"
                round
                @click="addEditRecommendedSettings"
                >新增备忘</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="操作人"
                align="left"
                prop="operator"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="操作时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作内容"
                align="left"
                prop="remark"
                min-width="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增备忘 - 弹框 -->
    <el-dialog
      title="新增备忘"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
      ><div class="ovy-a">
        <el-form
          :model="previewData"
          ref="ruleForm"
          :rules="rules"
          label-width="100px"
          style="width: 100%"
          ><el-form-item label="备忘：" prop="remark">
            <el-input
              type="textarea"
              maxlength="200"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 6}"
              v-model="previewData.remark"
            ></el-input>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-button @click="cancle('ruleForm')">取消</el-button>-->
<!--            <el-button-->
<!--              @click="addEditRecommendedSettingsOk('ruleForm')"-->
<!--              class="bgc-bv"-->
<!--              >确定</el-button-->
<!--            >-->
<!--          </el-form-item>-->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%;text-align: right">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="addEditRecommendedSettingsOk('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "hr_recruitModule_personnelLibrary_notesSettings",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 新增备忘 - 弹框
      previewLoding: false,
      // 新增备忘 - 弹框 - 数据
      previewData: {
        talentId: "", // 人才id
        remark: "", // 备忘
      },
      // 新增备忘 - 数据校验
      rules: {
        remark: [{ required: true, message: "请输入备忘", trigger: "blur" }],
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    this.previewData.talentId = this.$route.query.talentId;
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.$route.query.talentId,
      };
      this.doFetch({
        url: "/hr/talent/info/recommendLogPage",
        params,
        pageNum,
      });
    },
    // 新增备忘 - 弹框
    addEditRecommendedSettings() {
      this.previewLoding = true;
    },
    // 新增备忘 - 弹框 - 确定
    addEditRecommendedSettingsOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/hr/talent/info/recommend", {
            ...this.previewData,
          })
            .then((res) => {
              this.previewLoding = false;
              this.$refs[formName].resetFields();
              this.getData();
              this.$message({
                message: res.message,
                type: "success",
              });
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 取消
    cancle(formName) {
      this.$refs[formName].resetFields();
      this.previewLoding = false;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
